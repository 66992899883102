<template>
  <div class="container mescroll-touch">
    <p class="title">产品资料</p>
    <div class="productDetails">
      <img class="productImg" :src="ProductDetails.img" alt="" srcset="">
      <div class="details">
        <p class="producTitle">{{ ProductDetails.title }}</p>
        <div class="check" @click="checkPdf">点击查看</div><br>
        <div class="check" v-if="ProductDetails.IsProductModel" @click="productModel">产品模型</div>
      </div>
    </div>
    <p class="title">使用方法</p>
    <div class="category">
      <span :class="{'active': activeMenu == ''}" @click="changeMenu('')">全部</span>
      <span :class="{'active': activeMenu == item.TCode}" v-for="(item, index) in MenuList" :key="index" @click="changeMenu(item.TCode)">{{ item.DisplayName }}</span>
    </div>
    <div class="mescrollBox">
      <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
        <div class="list" id="dataList">
          <div class="item" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
            <div class="item_img">
              <img class="item_cover" :src="item.Thumbnail" />
            </div>
            <div class="item_content">
              <span class="item_title">{{item.Title}}</span>
            </div>
          </div>
        </div>
      </mescroll-vue>
    </div> 
  </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'
export default {
  name: 'ProductDetails',
  data() {
    return {
      ProductDetails: {},
      MenuList: [],
      activeMenu: '',
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
        isLock: false
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata"></p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        }
			}
    }
  },
  components: {
    MescrollVue
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    code() {
      return this.$route.query.code;
    }
  },
  created() {
    this.getProductDetails();
  },
  methods: {
    getProductDetails() {
      this.$axios.get('/Api/api/Web/article/GetArt?id=' + this.id).then(res => {
        this.ProductDetails = res.Data;
        this.getMenu(res.Data.code);
      })
    },
    getMenu(code) {
      this.$axios.get('/Api/api/Web/article/GetCode?code=' + code + '&type=2').then(res => {
        this.MenuList = res.Data;
      })
    },
    checkPdf() {
      if(this.ProductDetails.IsPDF) {
        this.$router.push({
          path: 'productPdf',
          query: {
            id: this.ProductDetails.id
          }
        });
      } else {
        this.$dialog.alert({
          title: '提示',
          message: '暂无产品资料',
        }).then(() => {});
      }
    },
    changeMenu(code) {
      this.activeMenu = code;
      this.list = [];
      this.mescroll.resetUpScroll(); // 刷新列表数据
    },
    // 获取最新上线列表数据
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArtRel', {
          id: this.id,
          code: this.activeMenu,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = [];
          if(res.Data.List) {
            arr = res.Data.List;
          }
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      this.getList(page.num, page.size);
		},
    toContentPage(Id, Type) {
      this.scrollTop = this.$refs.mescroll.$el.scrollTop;
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    },
    productModel() {
      this.$router.push({
        path: 'default',
        query: {
          type: 5
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
  background-color: #F8F8F8;
  .title {
    font-size: 15px;
    font-weight: bold;
    line-height: 40px;
  }
  .productDetails {
    display: flex;
    margin-bottom: 10px;
    .productImg {
      width: 40%;
      border-radius: 8px;
    }
    .details {
      margin-left: 15px;
      .producTitle {
        font-size: 14px;
        font-weight: bold;
      }
      .check {
        display: inline-block;
        font-size: 12px;
        border: 1px solid #FF6900;
        color: #FF6900;
        padding: 6px 15px;
        margin-top: 15px;
        border-radius: 3px;
      }
    }
  }
  .category {
    span {
      display: inline-block;
      height: 22px;
      line-height: 22px;
      border-radius: 22px;
      font-size: 12px;
      color: #959595;
      padding: 0 12px;
      border: 1px solid #959595;
      margin-right: 10px;
    }
    .active {
      color: #FFFFFF;
      border: 1px solid #FF6900;
      background-color: #FF6900;
    }
  }
  .mescrollBox {
    margin-top: 15px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      width: calc(calc(100% / 2) - 5px);
      border-radius: 6px;
      margin-bottom: 10px;
      background-color: #FFFFFF;
      overflow: hidden;
      .item_img {
        position: relative;
        .item_cover {
          display: block;
          width: 100%;
        }
        .playIcon {
          position: absolute;
          right: 12px;
          bottom: 12px;
          width: 30px;
          height: 30px;
        }
      }
      .item_content {
        padding: 5px 8px 8px;
        .item_title {
          height: 35px;
          font-size: 13px;
          line-height: 17px;
          color: #333333;
          overflow: hidden;
          text-overflow:ellipsis;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
          word-wrap: break-word;
        }
        .item_time {
          font-size: 12px;
          color: #999999;
          line-height: 24px;
        }
        .item_label {
          display: flex;
          flex-wrap: wrap;
          span {
            display: inline-block;
            height: 20px;
            line-height: 20px;
            border-radius: 2px;
            padding: 0 6px;
            margin-right: 6px;
            margin-bottom: 5px;
            background-color: rgba(255, 73, 98, 0.15);
            font-size: 11px;
            color: #CA001B;
          }
        }
        .item_nums {
          display: flex;
          justify-content: flex-end;
          .nums {
            display: flex;
            align-items: center;
            .watchIcon {
              width: 14px;
              margin: 0 2px 0 6px;
            }
            .likeIcon {
              width: 11px;
              margin: 0 2px 0 6px;
            }
            span {
              font-size: 11px;
              color: #666666;
            }
          }
        }
      }
    }    
    .item:nth-child(even) {
      margin-left: 10px;
    }
  }
}
</style>